import { fillIds } from '../pages/builder/helpers';

const DASHBOARD_URL = process.env.NODE_ENV === 'development' && false ?
  process.env.REACT_APP_DEVELOPMENT_DASHBOARD_URL : process.env.REACT_APP_DASHBOARD_URL;


/**
 * fetchForm
 * Fetch a form given the id. It also parses the json template and fill with new ids to avoid id clashing
 * in the editor
 * @param {Int} id Form id
 */
const fetchForm = async id => {
  const token = localStorage.getItem('access_token');
  const request = await fetch(`${DASHBOARD_URL}?v=` + Math.random(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    },
    body: JSON.stringify({
      query: `
        query($id: ID!) {
          form: forms_by_id(id: $id) {
            id,
            name,
            versions(sort: "-id", limit: 1) {
              id,
              date_created,
              date_updated,
              json
            },
          }
        }
      `,
      variables: {
        id
      }
    })
  });

  const result = await request.json();

  try {
    result.data.form.json = JSON.parse(result.data.form.versions[0].json);
    result.data.form.json = {
      ...result.data.form.json,
      fields: fillIds(result.data.form.json.fields)
    };
  } catch(e) {
    return null;
  }

  return result.data.form;
};

export { fetchForm };
