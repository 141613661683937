import { makeColums, makeOptions } from './helpers.js';

export const COMMON_NAME = {
  name: 'name',
  label: 'Field name',
  component: 'input-text',
  placeholder: '',
  hint: 'The name of the field and the key of the JSON',
  'react-rsuite5': {
    tooltip: true
  }
};

export const COMMON_LABEL = {
  name: 'label',
  label: 'Label',
  component: 'input-text-i18n',
  placeholder: 'Label of the field',
};

export const COMMON_TRITTICUS = [
  COMMON_NAME,
  COMMON_LABEL,
  {
    name: 'hint',
    label: 'Hint',
    component: 'input-text-i18n',
    placeholder: '',
  },
];

export const COMMON_PLACEHOLDER = {
  id: 'placeholder',
  name: 'placeholder',
  label: 'Placeholder',
  component: 'input-text-i18n',
  placeholder: 'i.e., Insert text here'
};

export const COMMON_HIDDEN = {
  name: 'hidden',
  component: 'toggle',
  label: 'Hidden'
};

export const COMMON_TRITTICUS_STATUS = {
  name: 'fields-status',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 },
    { name: 'three', size: 1 }
  ],
  fields: {
    one: [
      {
        name: 'disabled',
        component: 'toggle',
        label: 'Disabled'
      }
    ],
    two: [
      {
        name: 'readOnly',
        component: 'toggle',
        label: 'Read only'
      }
    ],
    three: [
      COMMON_HIDDEN
    ]
  }
};

export const COMMON_DUOS_STATUS = {
  name: 'fields-status',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 }
  ],
  fields: {
    one: [
      {
        name: 'disabled',
        component: 'toggle',
        label: 'Disabled'
      }
    ],
    two: [
      COMMON_HIDDEN
    ]
  }
};


export const COMMON_ARRAY_OPTIONS = {
  component: 'array',
  name: 'options',
  label: 'Select options',
  maxHeight: 350,
  fields: [
    {
      component: 'columns',
      columns: [
        { name: 'one', size: 0 },
        { name: 'two', size: 1 }
      ],
      name: 'array-option-item',
      fields: {
        one: [
          {
            name: 'value',
            component: 'input-text',
            placeholder: 'value',
            size: 'sm',
            width: 100,
            fullWidth: false
          }
        ],
        two: [
          {
            name: 'label',
            component: 'input-text-i18n',
            placeholder: 'label',
            size: 'sm',
            fullWidth: true
          }
        ]
      }
    }
  ]
};

export const COMMON_ARRAY_OPTIONS_WITH_IMAGE = {
  name: 'select-group-options',
  component: 'group',
  label: 'Options',
  collapsible: false,
  bottomBorder: true,
  fields: [
    {
      component: 'array',
      name: 'options',
      label: 'Select options',
      maxHeight: 350,
      fields: [
        {
          component: 'columns',
          columns: [
            { name: 'one', size: 0 },
            { name: 'two', size: 1 }
          ],
          name: 'array-option-item',
          fields: {
            one: [
              {
                name: 'value',
                component: 'input-text',
                placeholder: 'value',
                size: 'sm',
                width: 100,
                fullWidth: false
              }
            ],
            two: [
              {
                name: 'label',
                component: 'input-text-i18n',
                placeholder: 'label',
                size: 'sm',
                fullWidth: true
              }
            ]
          }
        },
        {
          name: 'image',
          component: 'input-text',
          placeholder: 'Image',
          fullWidth: true,
          size: 'sm'
        }
      ]
    },
    {
      component: 'checkbox',
      label: 'Options with images',
      name: 'showImageOptions',
      hint: 'Show image field for Select options to be displayed in the drop-down',
      script: `
      if (showImageOptions) {
        arraySetParam('options', 'image', 'hidden', false);
      } else {
        arraySetParam('options', 'image', 'hidden', true);
      }
      `,
      'react-rsuite5': {
        tooltip: true
      }
    }
  ]
};

export const COMMON_FILTER_OPTIONS = makeColums(
  {
    name: 'filterKey',
    label: 'Filter key',
    component: 'input-text',
    placeholder: 'i.e. label'
  },
  {
    name: 'filterValue',
    label: 'Filter Value',
    component: 'input-text'
  }
);

export const COMMON_COUNTRIES_OPTIONS = [
  {
    "value": "AF",
    "label": "Afghanistan"
  },
  {
    "value": "AL",
    "label": "Albania"
  },
  {
    "value": "DZ",
    "label": "Algeria"
  },
  {
    "value": "AD",
    "label": "Andorra"
  },
  {
    "value": "AO",
    "label": "Angola"
  },
  {
    "value": "AG",
    "label": "Antigua and Barbuda"
  },
  {
    "value": "AR",
    "label": "Argentina"
  },
  {
    "value": "AM",
    "label": "Armenia"
  },
  {
    "value": "AU",
    "label": "Australia"
  },
  {
    "value": "AT",
    "label": "Austria"
  },
  {
    "value": "AZ",
    "label": "Azerbaijan"
  },
  {
    "value": "BS",
    "label": "Bahamas"
  },
  {
    "value": "BH",
    "label": "Bahrain"
  },
  {
    "value": "BD",
    "label": "Bangladesh"
  },
  {
    "value": "BB",
    "label": "Barbados"
  },
  {
    "value": "BY",
    "label": "Belarus"
  },
  {
    "value": "BE",
    "label": "Belgium"
  },
  {
    "value": "BZ",
    "label": "Belize"
  },
  {
    "value": "BJ",
    "label": "Benin"
  },
  {
    "value": "BT",
    "label": "Bhutan"
  },
  {
    "value": "BO",
    "label": "Bolivia (Plurinational State of)"
  },
  {
    "value": "BA",
    "label": "Bosnia and Herzegovina"
  },
  {
    "value": "BW",
    "label": "Botswana"
  },
  {
    "value": "BR",
    "label": "Brazil"
  },
  {
    "value": "BN",
    "label": "Brunei Darussalam"
  },
  {
    "value": "BG",
    "label": "Bulgaria"
  },
  {
    "value": "BF",
    "label": "Burkina Faso"
  },
  {
    "value": "BI",
    "label": "Burundi"
  },
  {
    "value": "KH",
    "label": "Cambodia"
  },
  {
    "value": "CM",
    "label": "Cameroon"
  },
  {
    "value": "CA",
    "label": "Canada"
  },
  {
    "value": "CV",
    "label": "Cabo Verde"
  },
  {
    "value": "CF",
    "label": "Central African Republic"
  },
  {
    "value": "TD",
    "label": "Chad"
  },
  {
    "value": "CL",
    "label": "Chile"
  },
  {
    "value": "CN",
    "label": "China"
  },
  {
    "value": "CO",
    "label": "Colombia"
  },
  {
    "value": "KM",
    "label": "Comoros"
  },
  {
    "value": "CG",
    "label": "Congo"
  },
  {
    "value": "CD",
    "label": "Congo, Democratic Republic of the"
  },
  {
    "value": "CR",
    "label": "Costa Rica"
  },
  {
    "value": "CI",
    "label": "Côte d'Ivoire"
  },
  {
    "value": "HR",
    "label": "Croatia"
  },
  {
    "value": "CU",
    "label": "Cuba"
  },
  {
    "value": "CY",
    "label": "Cyprus"
  },
  {
    "value": "CZ",
    "label": "Czechia"
  },
  {
    "value": "DK",
    "label": "Denmark"
  },
  {
    "value": "DJ",
    "label": "Djibouti"
  },
  {
    "value": "DM",
    "label": "Dominica"
  },
  {
    "value": "DO",
    "label": "Dominican Republic"
  },
  {
    "value": "EC",
    "label": "Ecuador"
  },
  {
    "value": "EG",
    "label": "Egypt"
  },
  {
    "value": "SV",
    "label": "El Salvador"
  },
  {
    "value": "GQ",
    "label": "Equatorial Guinea"
  },
  {
    "value": "ER",
    "label": "Eritrea"
  },
  {
    "value": "EE",
    "label": "Estonia"
  },
  {
    "value": "ET",
    "label": "Ethiopia"
  },
  {
    "value": "FJ",
    "label": "Fiji"
  },
  {
    "value": "FI",
    "label": "Finland"
  },
  {
    "value": "FR",
    "label": "France"
  },
  {
    "value": "GA",
    "label": "Gabon"
  },
  {
    "value": "GM",
    "label": "Gambia"
  },
  {
    "value": "GE",
    "label": "Georgia"
  },
  {
    "value": "DE",
    "label": "Germany"
  },
  {
    "value": "GH",
    "label": "Ghana"
  },
  {
    "value": "GR",
    "label": "Greece"
  },
  {
    "value": "GD",
    "label": "Grenada"
  },
  {
    "value": "GT",
    "label": "Guatemala"
  },
  {
    "value": "GN",
    "label": "Guinea"
  },
  {
    "value": "GW",
    "label": "Guinea-Bissau"
  },
  {
    "value": "GY",
    "label": "Guyana"
  },
  {
    "value": "HT",
    "label": "Haiti"
  },
  {
    "value": "HN",
    "label": "Honduras"
  },
  {
    "value": "HU",
    "label": "Hungary"
  },
  {
    "value": "IS",
    "label": "Iceland"
  },
  {
    "value": "IN",
    "label": "India"
  },
  {
    "value": "ID",
    "label": "Indonesia"
  },
  {
    "value": "IR",
    "label": "Iran (Islamic Republic of)"
  },
  {
    "value": "IQ",
    "label": "Iraq"
  },
  {
    "value": "IE",
    "label": "Ireland"
  },
  {
    "value": "IL",
    "label": "Israel"
  },
  {
    "value": "IT",
    "label": "Italy"
  },
  {
    "value": "JM",
    "label": "Jamaica"
  },
  {
    "value": "JP",
    "label": "Japan"
  },
  {
    "value": "JO",
    "label": "Jordan"
  },
  {
    "value": "KZ",
    "label": "Kazakhstan"
  },
  {
    "value": "KE",
    "label": "Kenya"
  },
  {
    "value": "KI",
    "label": "Kiribati"
  },
  {
    "value": "KP",
    "label": "Korea (Democratic People's Republic of)"
  },
  {
    "value": "KR",
    "label": "Korea, Republic of"
  },
  {
    "value": "KW",
    "label": "Kuwait"
  },
  {
    "value": "KG",
    "label": "Kyrgyzstan"
  },
  {
    "value": "LA",
    "label": "Lao People's Democratic Republic"
  },
  {
    "value": "LV",
    "label": "Latvia"
  },
  {
    "value": "LB",
    "label": "Lebanon"
  },
  {
    "value": "LS",
    "label": "Lesotho"
  },
  {
    "value": "LR",
    "label": "Liberia"
  },
  {
    "value": "LY",
    "label": "Libya"
  },
  {
    "value": "LI",
    "label": "Liechtenstein"
  },
  {
    "value": "LT",
    "label": "Lithuania"
  },
  {
    "value": "LU",
    "label": "Luxembourg"
  },
  {
    "value": "MK",
    "label": "North Macedonia"
  },
  {
    "value": "MG",
    "label": "Madagascar"
  },
  {
    "value": "MW",
    "label": "Malawi"
  },
  {
    "value": "MY",
    "label": "Malaysia"
  },
  {
    "value": "MV",
    "label": "Maldives"
  },
  {
    "value": "ML",
    "label": "Mali"
  },
  {
    "value": "MT",
    "label": "Malta"
  },
  {
    "value": "MH",
    "label": "Marshall Islands"
  },
  {
    "value": "MR",
    "label": "Mauritania"
  },
  {
    "value": "MU",
    "label": "Mauritius"
  },
  {
    "value": "MX",
    "label": "Mexico"
  },
  {
    "value": "FM",
    "label": "Micronesia (Federated States of)"
  },
  {
    "value": "MA",
    "label": "Morocco"
  },
  {
    "value": "MD",
    "label": "Moldova, Republic of"
  },
  {
    "value": "MC",
    "label": "Monaco"
  },
  {
    "value": "MN",
    "label": "Mongolia"
  },
  {
    "value": "ME",
    "label": "Montenegro"
  },
  {
    "value": "MZ",
    "label": "Mozambique"
  },
  {
    "value": "MM",
    "label": "Myanmar"
  },
  {
    "value": "NA",
    "label": "Namibia"
  },
  {
    "value": "NR",
    "label": "Nauru"
  },
  {
    "value": "NP",
    "label": "Nepal"
  },
  {
    "value": "NL",
    "label": "Netherlands"
  },
  {
    "value": "NZ",
    "label": "New Zealand"
  },
  {
    "value": "NI",
    "label": "Nicaragua"
  },
  {
    "value": "NE",
    "label": "Niger"
  },
  {
    "value": "NG",
    "label": "Nigeria"
  },
  {
    "value": "NO",
    "label": "Norway"
  },
  {
    "value": "OM",
    "label": "Oman"
  },
  {
    "value": "PK",
    "label": "Pakistan"
  },
  {
    "value": "PW",
    "label": "Palau"
  },
  {
    "value": "PA",
    "label": "Panama"
  },
  {
    "value": "PG",
    "label": "Papua New Guinea"
  },
  {
    "value": "PY",
    "label": "Paraguay"
  },
  {
    "value": "PE",
    "label": "Peru"
  },
  {
    "value": "PH",
    "label": "Philippines"
  },
  {
    "value": "PL",
    "label": "Poland"
  },
  {
    "value": "PT",
    "label": "Portugal"
  },
  {
    "value": "QA",
    "label": "Qatar"
  },
  {
    "value": "RO",
    "label": "Romania"
  },
  {
    "value": "RU",
    "label": "Russian Federation"
  },
  {
    "value": "RW",
    "label": "Rwanda"
  },
  {
    "value": "KN",
    "label": "Saint Kitts and Nevis"
  },
  {
    "value": "LC",
    "label": "Saint Lucia"
  },
  {
    "value": "VC",
    "label": "Saint Vincent and the Grenadines"
  },
  {
    "value": "WS",
    "label": "Samoa"
  },
  {
    "value": "SM",
    "label": "San Marino"
  },
  {
    "value": "ST",
    "label": "Sao Tome and Principe"
  },
  {
    "value": "SA",
    "label": "Saudi Arabia"
  },
  {
    "value": "SN",
    "label": "Senegal"
  },
  {
    "value": "RS",
    "label": "Serbia"
  },
  {
    "value": "SC",
    "label": "Seychelles"
  },
  {
    "value": "SL",
    "label": "Sierra Leone"
  },
  {
    "value": "SG",
    "label": "Singapore"
  },
  {
    "value": "SK",
    "label": "Slovakia"
  },
  {
    "value": "SI",
    "label": "Slovenia"
  },
  {
    "value": "SB",
    "label": "Solomon Islands"
  },
  {
    "value": "SO",
    "label": "Somalia"
  },
  {
    "value": "ZA",
    "label": "South Africa"
  },
  {
    "value": "SS",
    "label": "South Sudan"
  },
  {
    "value": "ES",
    "label": "Spain"
  },
  {
    "value": "LK",
    "label": "Sri Lanka"
  },
  {
    "value": "SD",
    "label": "Sudan"
  },
  {
    "value": "SR",
    "label": "Suriname"
  },
  {
    "value": "SZ",
    "label": "Eswatini"
  },
  {
    "value": "SE",
    "label": "Sweden"
  },
  {
    "value": "CH",
    "label": "Switzerland"
  },
  {
    "value": "SY",
    "label": "Syrian Arab Republic"
  },
  {
    "value": "TJ",
    "label": "Tajikistan"
  },
  {
    "value": "TZ",
    "label": "Tanzania, United Republic of"
  },
  {
    "value": "TH",
    "label": "Thailand"
  },
  {
    "value": "TL",
    "label": "Timor-Leste"
  },
  {
    "value": "TG",
    "label": "Togo"
  },
  {
    "value": "TO",
    "label": "Tonga"
  },
  {
    "value": "TT",
    "label": "Trinidad and Tobago"
  },
  {
    "value": "TN",
    "label": "Tunisia"
  },
  {
    "value": "TR",
    "label": "Türkiye"
  },
  {
    "value": "TM",
    "label": "Turkmenistan"
  },
  {
    "value": "TV",
    "label": "Tuvalu"
  },
  {
    "value": "UG",
    "label": "Uganda"
  },
  {
    "value": "UA",
    "label": "Ukraine"
  },
  {
    "value": "AE",
    "label": "United Arab Emirates"
  },
  {
    "value": "GB",
    "label": "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    "value": "US",
    "label": "United States of America"
  },
  {
    "value": "UY",
    "label": "Uruguay"
  },
  {
    "value": "UZ",
    "label": "Uzbekistan"
  },
  {
    "value": "VU",
    "label": "Vanuatu"
  },
  {
    "value": "VE",
    "label": "Venezuela (Bolivarian Republic of)"
  },
  {
    "value": "VN",
    "label": "Viet Nam"
  },
  {
    "value": "YE",
    "label": "Yemen"
  },
  {
    "value": "ZM",
    "label": "Zambia"
  },
  {
    "value": "ZW",
    "label": "Zimbabwe"
  }
];

const AUTOCOMPLETES = [
  'off',
  'on',
  'name',
  'honorific-prefix',
  'given-name',
  'additional-name',
  'family-name',
  'honorific-suffix',
  'nickname',
  'email',
  'username',
  'new-password',
  'current-password',
  'one-time-code',
  'organization-title',
  'organization',
  'street-address',
  'address-line1',
  'address-line2',
  'address-line3',
  'address-level4',
  'address-level3',
  'address-level2',
  'address-level1',
  'country',
  'country-name',
  'postal-code',
  'cc-name',
  'cc-given-name',
  'cc-additional-name',
  'cc-family-name',
  'cc-number',
  'cc-exp',
  'cc-exp-month',
  'cc-exp-year',
  'cc-csc',
  'cc-type',
  'transaction-currency',
  'transaction-amount',
  'language',
  'bday',
  'bday-day',
  'bday-month',
  'bday-year',
  'sex',
  'tel',
  'tel-country-code',
  'tel-national',
  'tel-area-code',
  'tel-local',
  'tel-extension',
  'impp',
  'url',
  'photo'
];

const HTML_INPUT_TYPES = [
  'button',
  'checkbox',
  'color',
  'date',
  'datetime-local',
  'email',
  'file',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'radio',
  'range',
  'reset',
  'search',
  'submit',
  'tel',
  'text',
  'time',
  'url',
  'week'
];

const INPUT_MODES = [
  'none',
  'text',
  'decimal',
  'numeric',
  'tel',
  'search',
  'email',
  'url'
];

export const COMMON_HTML5 = {
  name: 'html5',
  component: 'group',
  label: 'HTML 5 tags',
  collapsible: true,
  open: false,
  fields: [
    makeColums(
      {
        name: 'inputMode',
        component: 'select',
        label: 'Input mode',
        placeholder: 'Select',
        placement: 'autoVerticalStart',
        cleanable: true,
        block: true,
        options: INPUT_MODES.map(item => ({ value: item, label: item }))
      },
      {
        name: 'autocomplete',
        component: 'select',
        label: 'Autocomplete',
        placeholder: 'Select',
        placement: 'autoVerticalStart',
        block: true,
        cleanable: true,
        options: AUTOCOMPLETES.map(item => ({ value: item, label: item }))
      },
      {
        name: 'inputType',
        component: 'select',
        label: 'Input type',
        placeholder: 'Select',
        placement: 'autoVerticalStart',
        block: true,
        cleanable: true,
        options: HTML_INPUT_TYPES.map(item => ({ value: item, label: item })),
        hint: 'Set the HTML 5 input type, some framework may have specific components for some of these type',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ]
};

const ALIGN_SELF_TYPES = [
  'auto', 'baseline', 'center',
  'end', 'flex-end', 'flex-start',
  'inherit', 'initial', 'normal',
  'revert', 'self-end', 'self-start',
  'start', 'stretch', 'unset'
];
export const COMMON_ALIGN_SELF = {
  name: 'leftAlignment',
  label: 'Left col align',
  component: 'select',
  cleanable: true,
  block: true,
  options: ALIGN_SELF_TYPES.map(item => ({ value: item, label: item })),
  hint: 'Flex "align-self" attribute for the column',
  'react-rsuite5': {
    tooltip: true
  }
};

export const COMMON_FULL_WIDTH = {
  component: 'toggle',
  name: 'fullWidth',
  label: 'Full width',
  hint: 'Set the width of the field to 100% of the enclosing container',
  'react-rsuite5': {
    tooltip: true
  }
};

export const COMMON_FULL_WIDTH_SIZE = makeColums(
  {
    component: 'toggle',
    name: 'fullWidth',
    label: 'Full width',
    hint: 'Set the width of the field to 100% of the enclosing container',
    'react-rsuite5': {
      tooltip: true
    },
    script: `
    if (fullWidth) {
      disable('width');
    } else {
      enable('width');
    }
    `
  },
  {
    name: 'width',
    component: 'input-number',
    label: 'Width',
    'react-rsuite5': {
      tooltip: true
    },
    hint: 'Set the width (in pixel) of the field',
    postfix: 'px',
    min: 0,
    max: 1024,
    step: 10
  }
);

export const COMMON_LAYOUT = {
  name: 'layout',
  component: 'select',
  label: 'Form layout',
  cleanable: false,
  searchable: false,
  options: [
    { value: 'vertical', label: 'Vertical' },
    { value: 'horizontal', label: 'Horizontal' },
    { value: 'inline', label: 'Inline' }
  ]
};

export const COMMON_VALIDATION = [
  {
    name: 'validation',
    component: 'group',
    label: 'Validation',
    open: false,
    collapsible: true,
    script: `
    if (required) {
      setParam('validation', 'open', true);
    }
    `,
    fields: [
      {
        name: 'required',
        component: 'toggle',
        label: 'Required'
      },
      {
        name: 'validationLength',
        component: 'columns',
        columns: [
          { name: 'one', size: 1 },
          { name: 'two', size: 1 }
        ],
        fields: {
          one: [
            {
              name: 'validationMinLength',
              component: 'input-number',
              label: 'Minimum length',
              placeholder: 'i.e. 10'
            },
            {
              name: 'validationMin',
              component: 'input-number',
              label: 'Minimum value',
              placeholder: 'i.e. 10'
            }
          ],
          two: [
            {
              name: 'validationMaxLength',
              component: 'input-number',
              label: 'Maximum length',
              placeholder: 'i.e. 10'
            },
            {
              name: 'validationMax',
              component: 'input-number',
              label: 'Maximum value',
              placeholder: 'i.e. 20'
            }
          ]
        }
      },
      {
        name: 'validationPattern',
        component: 'input-text',
        label: 'Pattern',
        placeholder: '^[a-z]$',
        prefix: '/',
        postfix: '/',
        hint: 'Regular expression for validation'
      },
      {
        name: 'validationMessage',
        component: 'input-text-i18n',
        label: 'Message',
        fullWidth: true,
        placeholder: 'Custom error message',
        textarea: true,
        rows: 4
      }
    ]
  }
];

export const COMMOM_MARKS = {
  label: 'Marks',
  name: 'marks',
  component: 'array',
  hint: 'Customize labels on the render ruler',
  fields: [
    {
      component: 'columns',
      columns: [
        { name: 'one', size: 0 },
        { name: 'two', size: 1 }
      ],
      name: 'marks-group',
      fields: {
        one: [
          {
            placeholder: 'Value',
            width: 100,
            fullWidth: false,
            name: 'value',
            component: 'input-number'
          }
        ],
        two: [
          {
            placeholder: 'Label',
            name: 'label',
            fullWidth: true,
            component: 'input-text-i18n'
          }
        ]
      }
    }
  ],
  'react-rsuite5': {
    tooltip: true
  }
};

export const COMMON_MARGIN_MIN_MAX = [
  makeColums(
    {
      name: 'maxWidth',
      component: 'input-number',
      label: 'Max width',
      min: 0,
      step: 5,
      postfix: 'px',
      allowClear: true
    },
    {
      name: 'maxHeight',
      component: 'input-number',
      label: 'Max height',
      min: 0,
      step: 5,
      postfix: 'px',
      allowClear: true
    }
  ),
  makeColums(
    {
      name: 'marginTop',
      component: 'input-number',
      label: 'Margin top',
      min: 0,
      step: 5,
      postfix: 'px',
      allowClear: true
    },
    {
      name: 'marginBottom',
      component: 'input-number',
      label: 'Margin bottom',
      min: 0,
      step: 5,
      postfix: 'px',
      allowClear: true
    }
  )
];

export const COMMON_MIN_MAX = {
  name: 'limits',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 },
    { name: 'three', size: 1 }
  ],
  fields: {
    one: [
      {
        label: 'Min',
        name: 'min',
        component: 'input-number',
      }
    ],
    two: [
      {
        label: 'Step',
        name: 'step',
        component: 'input-number',
      }
    ],
    three: [
      {
        label: 'Max',
        name: 'max',
        component: 'input-number',
      }
    ]
  }
};

export const COMMON_ALIGN_OFFSET = makeColums(
  [
    {
      name: 'align',
      component: 'select',
      label: 'Buttons alignment',
      options: makeOptions(['top', 'center', 'bottom']),
      searchable: false,
      hint: 'Vertical alignment of add / remove buttons',
      'react-rsuite5': {
        tooltip: true
      },
      script: `if (align === 'top' || align === 'bottom') {
        enable('alignOffset');
      } else {
        disable('alignOffset');
      }`
    }
  ],
  [
    {
      name: 'alignOffset',
      component: 'input-number',
      label: 'Offset',
      step: 1,
      min: 0,
      max: 1024,
      hint: `Offset of the add / remove button from the top / bottom (depends on the alignment)`
    }
  ]
);

export const COMMON_CLASS = [
  {
    name: 'group_css',
    component: 'group',
    label: 'HTML',
    collapsible: true,
    open: false,
    fields: [
      {
        name: 'className',
        component: 'input-text',
        label: 'CSS class'
      }
    ]
  }
];

const CURRENCIES_OPTIONS = [
  {
    value: "AFN",
    label: "Afghan Afghani"
  },
  {
    value: "ALL",
    label: "Albanian Lek"
  },
  {
    value: "DZD",
    label: "Algerian Dinar"
  },
  {
    value: "ARS",
    label: "Argentine Peso"
  },
  {
    value: "AMD",
    label: "Armenian Dram"
  },
  {
    value: "AUD",
    label: "Australian Dollar"
  },
  {
    value: "AZN",
    label: "Azerbaijani Manat"
  },
  {
    value: "BHD",
    label: "Bahraini Dinar"
  },
  {
    value: "BDT",
    label: "Bangladeshi Taka"
  },
  {
    value: "BYN",
    label: "Belarusian Ruble"
  },
  {
    value: "BZD",
    label: "Belize Dollar"
  },
  {
    value: "BOB",
    label: "Bolivian Boliviano"
  },
  {
    value: "BAM",
    label: "Bosnia-Herzegovina Convertible Mark"
  },
  {
    value: "BWP",
    label: "Botswanan Pula"
  },
  {
    value: "BRL",
    label: "Brazilian Real"
  },
  {
    value: "GBP",
    label: "British Pound Sterling"
  },
  {
    value: "BND",
    label: "Brunei Dollar"
  },
  {
    value: "BGN",
    label: "Bulgarian Lev"
  },
  {
    value: "BIF",
    label: "Burundian Franc"
  },
  {
    value: "XOF",
    label: "CFA Franc BCEAO"
  },
  {
    value: "XAF",
    label: "CFA Franc BEAC"
  },
  {
    value: "KHR",
    label: "Cambodian Riel"
  },
  {
    value: "CAD",
    label: "Canadian Dollar"
  },
  {
    value: "CVE",
    label: "Cape Verdean Escudo"
  },
  {
    value: "CLP",
    label: "Chilean Peso"
  },
  {
    value: "CNY",
    label: "Chinese Yuan"
  },
  {
    value: "COP",
    label: "Colombian Peso"
  },
  {
    value: "KMF",
    label: "Comorian Franc"
  },
  {
    value: "CDF",
    label: "Congolese Franc"
  },
  {
    value: "CRC",
    label: "Costa Rican Colón"
  },
  {
    value: "HRK",
    label: "Croatian Kuna"
  },
  {
    value: "CZK",
    label: "Czech Republic Koruna"
  },
  {
    value: "DKK",
    label: "Danish Krone"
  },
  {
    value: "DJF",
    label: "Djiboutian Franc"
  },
  {
    value: "DOP",
    label: "Dominican Peso"
  },
  {
    value: "EGP",
    label: "Egyptian Pound"
  },
  {
    value: "ERN",
    label: "Eritrean Nakfa"
  },
  {
    value: "EEK",
    label: "Estonian Kroon"
  },
  {
    value: "ETB",
    label: "Ethiopian Birr"
  },
  {
    value: "EUR",
    label: "Euro"
  },
  {
    value: "GEL",
    label: "Georgian Lari"
  },
  {
    value: "GHS",
    label: "Ghanaian Cedi"
  },
  {
    value: "GTQ",
    label: "Guatemalan Quetzal"
  },
  {
    value: "GNF",
    label: "Guinean Franc"
  },
  {
    value: "HNL",
    label: "Honduran Lempira"
  },
  {
    value: "HKD",
    label: "Hong Kong Dollar"
  },
  {
    value: "HUF",
    label: "Hungarian Forint"
  },
  {
    value: "ISK",
    label: "Icelandic Króna"
  },
  {
    value: "INR",
    label: "Indian Rupee"
  },
  {
    value: "IDR",
    label: "Indonesian Rupiah"
  },
  {
    value: "IRR",
    label: "Iranian Rial"
  },
  {
    value: "IQD",
    label: "Iraqi Dinar"
  },
  {
    value: "ILS",
    label: "Israeli New Sheqel"
  },
  {
    value: "JMD",
    label: "Jamaican Dollar"
  },
  {
    value: "JPY",
    label: "Japanese Yen"
  },
  {
    value: "JOD",
    label: "Jordanian Dinar"
  },
  {
    value: "KZT",
    label: "Kazakhstani Tenge"
  },
  {
    value: "KES",
    label: "Kenyan Shilling"
  },
  {
    value: "KWD",
    label: "Kuwaiti Dinar"
  },
  {
    value: "LVL",
    label: "Latvian Lats"
  },
  {
    value: "LBP",
    label: "Lebanese Pound"
  },
  {
    value: "LYD",
    label: "Libyan Dinar"
  },
  {
    value: "LTL",
    label: "Lithuanian Litas"
  },
  {
    value: "MOP",
    label: "Macanese Pataca"
  },
  {
    value: "MKD",
    label: "Macedonian Denar"
  },
  {
    value: "MGA",
    label: "Malagasy Ariary"
  },
  {
    value: "MYR",
    label: "Malaysian Ringgit"
  },
  {
    value: "MUR",
    label: "Mauritian Rupee"
  },
  {
    value: "MXN",
    label: "Mexican Peso"
  },
  {
    value: "MDL",
    label: "Moldovan Leu"
  },
  {
    value: "MAD",
    label: "Moroccan Dirham"
  },
  {
    value: "MZN",
    label: "Mozambican Metical"
  },
  {
    value: "MMK",
    label: "Myanma Kyat"
  },
  {
    value: "NAD",
    label: "Namibian Dollar"
  },
  {
    value: "NPR",
    label: "Nepalese Rupee"
  },
  {
    value: "TWD",
    label: "New Taiwan Dollar"
  },
  {
    value: "NZD",
    label: "New Zealand Dollar"
  },
  {
    value: "NIO",
    label: "Nicaraguan Córdoba"
  },
  {
    value: "NGN",
    label: "Nigerian Naira"
  },
  {
    value: "NOK",
    label: "Norwegian Krone"
  },
  {
    value: "OMR",
    label: "Omani Rial"
  },
  {
    value: "PKR",
    label: "Pakistani Rupee"
  },
  {
    value: "PAB",
    label: "Panamanian Balboa"
  },
  {
    value: "PYG",
    label: "Paraguayan Guarani"
  },
  {
    value: "PEN",
    label: "Peruvian Nuevo Sol"
  },
  {
    value: "PHP",
    label: "Philippine Peso"
  },
  {
    value: "PLN",
    label: "Polish Zloty"
  },
  {
    value: "QAR",
    label: "Qatari Rial"
  },
  {
    value: "RON",
    label: "Romanian Leu"
  },
  {
    value: "RUB",
    label: "Russian Ruble"
  },
  {
    value: "RWF",
    label: "Rwandan Franc"
  },
  {
    value: "SAR",
    label: "Saudi Riyal"
  },
  {
    value: "RSD",
    label: "Serbian Dinar"
  },
  {
    value: "SGD",
    label: "Singapore Dollar"
  },
  {
    value: "SOS",
    label: "Somali Shilling"
  },
  {
    value: "ZAR",
    label: "South African Rand"
  },
  {
    value: "KRW",
    label: "South Korean Won"
  },
  {
    value: "LKR",
    label: "Sri Lankan Rupee"
  },
  {
    value: "SDG",
    label: "Sudanese Pound"
  },
  {
    value: "SEK",
    label: "Swedish Krona"
  },
  {
    value: "CHF",
    label: "Swiss Franc"
  },
  {
    value: "SYP",
    label: "Syrian Pound"
  },
  {
    value: "TZS",
    label: "Tanzanian Shilling"
  },
  {
    value: "THB",
    label: "Thai Baht"
  },
  {
    value: "TOP",
    label: "Tongan Paʻanga"
  },
  {
    value: "TTD",
    label: "Trinidad and Tobago Dollar"
  },
  {
    value: "TND",
    label: "Tunisian Dinar"
  },
  {
    value: "TRY",
    label: "Turkish Lira"
  },
  {
    value: "USD",
    label: "US Dollar"
  },
  {
    value: "UGX",
    label: "Ugandan Shilling"
  },
  {
    value: "UAH",
    label: "Ukrainian Hryvnia"
  },
  {
    value: "AED",
    label: "United Arab Emirates Dirham"
  },
  {
    value: "UYU",
    label: "Uruguayan Peso"
  },
  {
    value: "UZS",
    label: "Uzbekistan Som"
  },
  {
    value: "VEF",
    label: "Venezuelan Bolívar"
  },
  {
    value: "VND",
    label: "Vietnamese Dong"
  },
  {
    value: "YER",
    label: "Yemeni Rial"
  },
  {
    value: "ZMK",
    label: "Zambian Kwacha"
  },
  {
    value: "ZWL",
    label: "Zimbabwean Dollar"
  }
];


export const COMMON_CURRENCIES = {
  component: 'select',
  name: 'currency',
  label: 'Currency',
  cleanable: false,
  searchable: true,
  options: CURRENCIES_OPTIONS
};
