import React from 'react';
import { useDrag } from 'react-dnd';

import './draggable-panels.scss';

const ComponentCard = ({ component }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'component',
    item: () => {
      return {
        id: component.name,
        componentName: component.name,
        dropType: 'component',
        template: component.template === true,
        templateId: component.templateId
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.5 : 1
  const Icon = component.icon;

  return (
    <div ref={drag} style={{ opacity }} className="lf-component-card">
      {Icon && <div className="icon"><Icon size={20}/></div>}
      {component.label}
    </div>
  );
};


const CustomComponentCard = ({ form }) => {

  const [{ isDragging }, drag] = useDrag({
    type: 'custom-component',
    item: () => {
      return {
        formId: form.id,
        customComponentName: form.name,
        dropType: 'custom-component'
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.5 : 1

  return (
    <div ref={drag} style={{ opacity }} className="lf-custom-component-card">
      {form.name}
    </div>
  );
};

export {
  ComponentCard,
  CustomComponentCard
};
