import { definePlugin } from '../../code-plug';

import { ComponentsPanel } from './views/components-panel';

definePlugin('builder-dnd-components', ({ registerView }, { builderProjectIds = [], builderDescription }) => {

  registerView(
    'builder-tabs',
    ComponentsPanel,
    {
      tabName: 'Components',
      tabKey: 'components',
      position: 'top',
      builderProjectIds,
      builderDescription
    }
  );

});
