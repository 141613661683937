import { definePlugin } from '../../code-plug';

import { CompleteTranslationsMenuItem } from './views/complete-translations-menu-item';
import { CompleteTranslationsSidebarButton } from './views/complete-translations-sidebar-button';

definePlugin('builder-complete-translations', ({ registerView }) => {

  // register the toolbar menu item to add a field
  registerView(
    'form-builder-toolbar',
    CompleteTranslationsMenuItem,
    {
      key: 'form-builder-complete-translations',
      event: 'completeTranslations',
      parent: 'Fields',
      group: 'modify'
    }
  );

  // register sidebar button to open translations
  registerView(
    'form-builder-languages',
    CompleteTranslationsSidebarButton
  );

});
