import React, { useCallback } from 'react';

import { useModal } from '../modal';
import { PreviewJson } from '../preview-json';

const ModalJSON = ({ value }) => {
  return (
    <div>
      {value.description && (
        <div>{value.description}</div>
      )}
      <PreviewJson json={value.json} height="80vh" />
    </div>
  );
};

const usePreviewJSONModal = () => {
  const { open, close } = useModal({
    view: ModalJSON,
    size: 'md',
    name: `edit-json-modal`,
    className: 'lf-modal-json-preview',
    align: 'center',
    labelCancel: null,
    labelSubmit: 'Close'
  });

  const handleOpenJSONModal = useCallback(
    async (json, { title, description } = {}) => {
      await open({ json, description }, { title });
    },
    [open]
  );

  const handleCloseJSONModal = useCallback(
    () => close(),
    [close]
  );

  return {
    openJSONModal: handleOpenJSONModal,
    closeJSONModal: handleCloseJSONModal
  };
};

export { usePreviewJSONModal };
