import React, { useRef } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd'

import { getFieldById } from '../../../components/lets-form';

import './placeholder-wrapper.scss';

const PlaceholderWrapper = ({
  nextField,
  parentField,
  parentFieldTarget,
  parentFieldSubTarget,
  fields,
  onMove = () => {},
  onAppend = () => {},
  onAddField = () => {},
  onAddTemplate = () => {},
  onAddForm = () => {}
}) => {
  const ref = useRef(null)
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ['card', 'component', 'custom-component'],
    collect(monitor) {
      // it's the item being dragged
      const item = monitor.getItem();
      // if not hover or if it's hovering over itself, then it's not over
      if (!monitor.isOver() || (item && parentField && item.fieldId === parentField.id)) {
        return {
          isOver: false,
          handlerId: monitor.getHandlerId()
        };
      }
      return {
        isOver: true,
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item) {
      if (item.dropType === 'field') {
        // dragging a field from the form
        if (!ref.current || (item && parentField && item.fieldId === parentField.id)) {
          return;
        }
        // if field is empty, append to the parent, otherwise move
        if (nextField) {
          onMove(item.id, nextField?.id);
        } else {
          onAppend(
            getFieldById(fields, item.id),
            parentField,
            parentFieldTarget,
            parentFieldSubTarget
          );
        }
      } else if (item.dropType === 'component' && !item.template) {
        // dragging a component from the component sidebar
        onAddField(
          item.componentName,
          parentField,
          parentFieldTarget,
          parentFieldSubTarget
        );
      } else if (item.dropType === 'component' && item.template) {
        // dragging a component from the component sidebar
        onAddTemplate(
          item.templateId,
          parentField,
          parentFieldTarget,
          parentFieldSubTarget
        );
      } else if (item.dropType === 'custom-component') {
        // dragging a component from the component sidebar
        console.log('----- adding form', item)
        onAddForm(
          item.formId,
          parentField,
          parentFieldTarget,
          parentFieldSubTarget
        );
        /*onAddTemplate(
          item.templateId,
          parentField,
          parentFieldTarget,
          parentFieldSubTarget
        );*/
      }
    }
  });
  // only allows dropping
  drop(ref)

  return (
    <div
      className={classNames('form-generator-placeholder-wrapper', { hovering: isOver })}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div className="component">&nbsp;</div>
    </div>
  );
};

export { PlaceholderWrapper };
