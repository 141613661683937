import _ from 'lodash';

import { mapFields } from '../../../components/lets-form/index';;


/**
 * fillIds
 * Ensure every field has a unique id to identify it inside the UI. May force to create it even if exists
 * @param {*} fields
 * @param {*} namespace
 * @param {*} force
 * @returns
 */
const fillIds = (fields, namespace = '', force = false) => {
  return mapFields(
    fields,
    field => {
      // if not id, then create it
      if (!field.id || force) {
        return {
          ...field,
          id: _.uniqueId(namespace)
        };
      }
      return field;
    }
  );
};

export { fillIds }