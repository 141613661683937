import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd'
import { ButtonGroup, Button } from 'rsuite';

import { isChildrenOf } from '../../../components/lets-form';

import './edit-wrapper.scss';

const EditWrapper = ({
  children,
  field,
  fields,
  onEdit,
  onDelete,
  selected = false,
  onMove = () => {},
  onAddField = () => {},
  onAddTemplate = () => {},
  onAddForm = () => {},
  onHover = () => {},
  index
}) => {
  const [hover, setHover] = useState(false);
  const [hoverButton, setHoverButton] = useState(false);

  const ref = useRef(null)
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ['card', 'component', 'custom-component'],
    collect(monitor) {
      // it's the item being dragged
      const item = monitor.getItem();
      if (!monitor.isOver() ||
        item?.fieldId === field.id || // don't drop on itsefl
        isChildrenOf(field?.id, item?.id, fields) // don't drop inside itself
      ) {
        return {
          isOver: false,
          handlerId: monitor.getHandlerId()
        };
      }

      return {
        isOver: true,
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item, monitor) {
      if (item.dropType === 'field') {
        if (!ref.current ||
          item.fieldId === field.id ||
          isChildrenOf(field?.id, item?.id, fields)
        ) {
          return;
        }
        onMove(item.id, field.id);
      } else if (item.dropType === 'component' && !item.template) {
        onAddField(item.componentName, field.id);
      } else if (item.dropType === 'component' && item.template) {
        onAddTemplate(item.templateId, field.id);
      } else if (item.dropType === 'custom-component') {
        onAddForm(item.formId, field.id);
      }
    }
  });

  // TODO if not a field, then it cannot be dropped
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return {
        id: field?.id,
        fieldId: field?.id,
        index: index,
        dropType: 'field'
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      fieldId: field?.id
    })
  });

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref));

  const handleMouseEnter = useCallback(
    () => {
      setHover(true);
    },
    []
  );
  const handleMouseLeaveButton = useCallback(
    () => {
      setHoverButton(false);
    },
    []
  );
  const handleMouseEnterButton = useCallback(
    () => {
      setHoverButton(true);
    },
    []
  );
  const handleMouseLeave = useCallback(
    () => {
      setHover(false);
    },
    []
  );
  const handleEdit = useCallback(
    () => {
      onEdit(field);
    },
    [field, onEdit]
  );
  const handleDelete = useCallback(
    () => {
      onDelete(field);
    },
    [field, onDelete]
  );

  return (
    <div
      className={classNames('form-generator-field-wrapper', { hovering: isOver, selected, hover: hoverButton, handle: hover })}
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="component"
        style={{ opacity: field.hidden === true ? 0.3 : 1 }}
      >
        {children}
      </div>
      {field.hidden === true && (
        <div className="hidden">
          hidden
        </div>
      )}
      {hover && (
        <div className="buttons">
          <ButtonGroup
            onMouseOver={onHover}
            onMouseEnter={handleMouseEnterButton}
            onMouseLeave={handleMouseLeaveButton}
          >
            <Button
              appearance="primary"
              size="xs"
              onClick={handleEdit}

            >Edit</Button>
            <Button
              appearance="primary"
              size="xs"
              onClick={handleDelete}
            >Delete</Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export { EditWrapper };
