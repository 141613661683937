import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';


import Manifests from '../../../manifest';
import { fetchTemplates } from '../../../helpers';
import { getUnavailableComponents } from '../../../helpers/get-unavailable-components';
import { AlchemicLoader } from '../../../components/alchemic-loader';
import { useFormBuilderStore } from '../../../pages/builder/state';
import { lfError } from '../../../components/lets-form/helpers/lf-log';
import { MarkdownText } from '../../../components';

import { ComponentCard, CustomComponentCard } from './draggable-panels';
import { GET_COMPONENTS } from '../queries';
import { Tooltip, Whisper } from 'rsuite';

const ControlCategories = {
  'general': 'Basic fields',
  'layout': 'Layout fields',
  'advanced': 'Advanced'
};

const ComponentsPanel = ({
  disabledComponents,
  builderProjectIds,
  builderDescription
}) => {
  const [templates, setTemplates] = useState([]);
  const framework = useFormBuilderStore(state => state.framework);
  const { data: customComponents, loading: loadingComponents } = useQuery(GET_COMPONENTS, {
    variables: {
      ids: builderProjectIds
    },
    skip: _.isEmpty(builderProjectIds),
    onError: e => {
      lfError('Error loading custom components from projects', e);
    },
    context: {
      noAuth: true
    }
  });

  // fetch advanced fields (templates)
  useEffect(
    () => {
      const r = async () => {
        const templates = await fetchTemplates({ component: true });
        setTemplates(templates.templates);
      }
      r();
    },
    []
  );

  const hiddenComponents = Object.keys(Manifests)
    .reduce(
      (acc, componentName) => Manifests[componentName].hidden ? [...acc, componentName] : acc,
      disabledComponents ?? []
    );
  const unavailableComponents = getUnavailableComponents(framework);

  let manifests = _.chain(Manifests)
    .omit(hiddenComponents)
    .omit(unavailableComponents)
    .groupBy('category')
    .value();

  // merge with advanced components
  if (Array.isArray(templates) && templates.length !== 0) {
    const items = templates.map(template => ({
      category: 'advanced',
      template: true,
      templateId: template.id,
      label: template.name,
      name: template.name,
      frameworks: template.frameworks,
      description: template.description
    }));

    manifests = {
      ...manifests,
      advanced: [
        ...(manifests.advanced ?? []),
        ...items
      ]
    };
  }

  const categories = Object.keys(manifests).filter(key => key !== 'undefined');

  return (
    <div className="lf-dnd-components">
      <div className="dnd-help">
        {builderDescription && (
          <MarkdownText>{builderDescription}</MarkdownText>
        )}
        {!builderDescription && (
          <span>Drag these components to add fields to the form</span>
        )}
      </div>
      {categories.map(category => (
        <div className="category" key={category}>
          <div className="category-name">{ControlCategories[category]}</div>
          <div className="components">
            {manifests[category].map(component => (
              <ComponentCard component={component} key={component.name}/>
            ))}
          </div>
        </div>
      ))}
      {customComponents?.projects.map(project => (
        <div className="category" key={`project-id-${project.id}`}>
          <div className="category-name">
            {project.name}
            {project.description && (
              <Whisper
                trigger="hover"
                placement="topEnd"
                speaker={<Tooltip><MarkdownText>{project.description}</MarkdownText></Tooltip>}
              >
                <InfoOutlineIcon style={{ marginLeft: '5px' }}/>
              </Whisper>
            )}
          </div>
          <div className="components">
            {customComponents.forms
              .filter(form => form.projectId.id === project.id)
              .map(form => (
                <CustomComponentCard form={form} key={'form-panel-' + form.id}/>
              )
            )}
          </div>
        </div>
      ))}
      {loadingComponents && <AlchemicLoader margin={40} />}
    </div>
  );
};

export { ComponentsPanel };
