import React from 'react';
import {
  Form,
  ButtonToolbar,
  Dropdown
} from 'rsuite';
import _ from 'lodash';

import { useRegion, useRawRegion } from '../../../code-plug';
import { useFormBuilderStore } from '../../../pages/builder/state';

import './toolbar.scss';

const Divider = () => <Dropdown.Separator />;

/**
 * collectMenuItems
 * Respect registration order
 * @param {*} items
 * @param {*} parent
 * @returns
 */
const collectMenuItems = (items, parent) => {

  const filtered = items.filter(({ params }) => params?.parent === parent);
  // group menu items
  const groupedItems = _.groupBy(filtered, item => item.params.group ?? 'bottom');
  // always put top and bottom groups at the beginning and end of array
  let orderedGroups = _.keys(groupedItems);
  orderedGroups = [
    ...(groupedItems.top ? ['top'] : []),
    ...(orderedGroups.filter(key => key !== 'top' && key !== 'bottom')),
    ...(groupedItems.bottom ? ['bottom'] : []),
  ];

  // aggregate by group, beginning of new group there's a divider
  const result = orderedGroups.reduce(
    (acc, group) => {
      return [
        ...acc,
        acc.length !== 0 ? {
          View: Divider,
          params: { key: 'group-' + group }
        } : null,
        ...groupedItems[group]
      ].filter(Boolean);
    },
    []
  );

  return result;
};


const Toolbar = ({ form }) => {
  const disableToolbar = useFormBuilderStore(state => state.disableToolbar);
  const renderFormToolbar = useRegion('form-builder-toolbar');
  const menuElements = useRawRegion('form-builder-toolbar')

  const formItems = collectMenuItems(menuElements, 'Form');
  const fieldItems = collectMenuItems(menuElements, 'Fields');

  return (
    <div className="lf-toolbar">
      <Form>
        <div className="form-name">
          <span className="label-name">Name</span>
          <span className="name">{form.name || 'Unnammed'}</span>
        </div>
        <ButtonToolbar style={{ float: 'right', marginTop: '3px' }}>
          {renderFormToolbar({ disabled: disableToolbar }, ({ params }) => _.isEmpty(params?.parent) && params?.start)}
          <Dropdown
            title="Fields"
            className="float-dropdown"
            menuStyle={{ backgroundColor: '#ffffff' }}
            disabled={disableToolbar}
          >
            {fieldItems.map(({ params, View }) => (
              <View {...params} />
            ))}
          </Dropdown>
          <Dropdown
            title="Form"
            style={{ backgroundColor: '#ffffff' }}
            disabled={disableToolbar}
          >
            {formItems.map(({ params, View }) => (
              <View {...params} />
            ))}
          </Dropdown>
          {renderFormToolbar({ disabled: disableToolbar }, ({ params }) => _.isEmpty(params?.parent) && params?.end)}
        </ButtonToolbar>
      </Form>
    </div>
  );
};

export { Toolbar };
