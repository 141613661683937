/* eslint-disable import/first */
import { manifest as TextInputManifest } from './src/input-text.js';
import { manifest as ToggleManifest } from'./src/toggle.js';
import { manifest as FieldsGroupManifest } from './src/group.js';
import { manifest as ListArrayManifest } from './src/array.js';
import { manifest as InputNumberManifest } from './src/input-number.js';
import { manifest as SliderManifest } from './src/slider.js';
import { manifest as DateManifest } from './src/date.js';
import { manifest as CheckboxManifest } from './src/checkbox.js';
import { manifest as CheckboxGroupManifest } from './src/checkbox-group.js';
import { manifest as RadioGroupManifest } from './src/radio-group.js';
import { manifest as InputTagManifest } from './src/input-tag.js';
import { manifest as InputMaskManifest } from './src/input-mask.js';
import { manifest as TextareaManifest } from './src/textarea.js';
import { manifest as RateManifest } from './src/rate.js';
import { manifest as PlaceholderManifest } from './src/placeholder.js';
import { manifest as MultiselectManifest } from './src/multiselect.js';
import { manifest as TwoColumnsManifest } from './src/two-columns.js';
import { manifest as ThreeColumnsManifest } from './src/three-columns.js';
import { manifest as InputTextI18NManifest } from './src/input-text-i18n.js';
import { manifest as PlaceholderImageManifest } from './src/placeholder-image.js';
import { manifest as ButtonManifest } from './src/button.js';
import { manifest as DividerManifest } from './src/divider.js';
import { manifest as SelectManifest } from'./src/select.js';
import { manifest as DatetimeManifest } from './src/datetime.js';
import { manifest as TabsManifest } from './src/tabs.js';
import { manifest as RadioTileManifest } from './src/radio-tile.js';
import { manifest as UploadManifest } from './src/upload.js';
import { manifest as ButtonsToggleGroupManifest } from './src/buttons-toggle-group.js';
import { manifest as StepsManifest } from './src/steps.js';
import { manifest as ModuleManifest } from './src/esm-module.js';
import { manifest as TimeManifest } from './src/time.js';
import { manifest as HiddenManifest } from './src/hidden.js';
import { manifest as ColumnsManifest } from './src/columns.js';
import { manifest as CurrencyManifest } from './src/currency.js';

import { manifest as FormRsuite5 } from './src/form-rsuite5.js';
import { manifest as FormBootstrap } from './src/form-bootstrap.js';
import { manifest as FormMaterialUI } from './src/form-material-ui.js';
import { manifest as FormAntd } from './src/form-antd.js';
import { manifest as FormReact } from './src/form-react.js';
import { manifest as FormReactMantine } from './src/form-mantine.js';



const Manifests = {
  'input-text': TextInputManifest,
  'toggle': ToggleManifest,
  'select': SelectManifest,
  'group': FieldsGroupManifest,
  'two-columns': TwoColumnsManifest,
  'three-columns': ThreeColumnsManifest,
  'columns': ColumnsManifest,
  'array': ListArrayManifest,
  'input-number': InputNumberManifest,
  'slider': SliderManifest,
  'date': DateManifest,
  'checkbox': CheckboxManifest,
  'checkbox-group': CheckboxGroupManifest,
  'radio-group': RadioGroupManifest,
  'input-tag': InputTagManifest,
  'input-mask': InputMaskManifest,
  'textarea': TextareaManifest,
  'rate': RateManifest,
  'placeholder': PlaceholderManifest,
  'multiselect': MultiselectManifest,
  'input-text-i18n': InputTextI18NManifest,
  'placeholder-image': PlaceholderImageManifest,
  'button': ButtonManifest,
  'divider': DividerManifest,
  'datetime': DatetimeManifest,
  'tabs': TabsManifest,
  'radio-tile': RadioTileManifest,
  'upload': UploadManifest,
  'buttons-toggle-group': ButtonsToggleGroupManifest,
  'steps': StepsManifest,
  'esm-module': ModuleManifest,
  'time': TimeManifest,
  'hidden': HiddenManifest,
  'currency': CurrencyManifest,
  'forms': {
    'react-rsuite5': FormRsuite5,
    'react-bootstrap': FormBootstrap,
    'react-material-ui': FormMaterialUI,
    'react-antd': FormAntd,
    'react': FormReact,
    'react-mantine': FormReactMantine
  }
};

export default Manifests;