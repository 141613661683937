import {
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_PREFIXES,
  RSUITE5_SIZE,
  ANTD_SIZE,
  ANTD_PREFIXES,
  ANTD_ALLOW_CLEAR,
  ANTD_COUNT_CHARS,
  ANTD_HINT_AS_TOOLTIP,
  ANTD_MAX_LENGTH,
  RSUITE5_HINT_AS_TOOLTIP,
  COMMON_TRITTICUS,
  COMMON_FULL_WIDTH_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  MANTINE_SECTIONS,
  ANTD_VARIANT,
  COMMON_CURRENCIES
} from '../costants/index.js';

const manifest = {
  label: 'Currency',
  category: 'general',
  name: 'currency',
  description: 'Formatted currency input box',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS,
    COMMON_CURRENCIES
  ],
  'react': [
    COMMON_FULL_WIDTH_SIZE
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_SIZE,
      []
    ),
    COMMON_FULL_WIDTH_SIZE,
    RSUITE5_PREFIXES,
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-bootstrap': [

  ],
  'react-material-ui': [

  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    ANTD_PREFIXES,
    makeColums(
      ANTD_COUNT_CHARS,
      ANTD_MAX_LENGTH
    ),
    makeColums(
      ANTD_ALLOW_CLEAR,
      ANTD_HINT_AS_TOOLTIP
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    MANTINE_SECTIONS,
    makeColums(
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    )
  ],
  'form-generator': {

  },
  defaultValues: {
    'react-mantine': {
      'react-mantine': {

      }
    },
    'react': {
      'react': {

      }
    },
    'react-bootstrap': {
      'react-bootstrap': {

      }
    },
    'react-rsuite5': {
      'react-rsuite5': {

      }
    },
    'react-material-ui': {
      'react-material-ui': {

      }
    },
    'react-antd': {
      'react-antd': {

      }
    }
  }
};

export { manifest };