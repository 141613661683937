import { PostHogProvider } from 'posthog-js/react'

export const AnalyticsProvider = ({ children, ...rest }) => {
  return process.env.NODE_ENV !== 'development' ? (
    <PostHogProvider
      apiKey="phc_H7jis35kpEnmnsTqoKNFvzfwam4TVLBPJqh4i9Ryz6u"
      options={{ api_host: 'https://app.posthog.com' }}
      {...rest}
    >{children}</PostHogProvider>
  ) : <>{children}</>
};