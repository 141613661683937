import { definePlugin } from '../../code-plug';

import { DebugPanelContainer } from './views/debug-panel-container';

definePlugin('builder-debug', ({ registerView }) => {

  registerView(
    'builder-tabs',
    DebugPanelContainer,
    {
      tabName: 'Debug',
      tabKey: 'debug'
    }
  );

  registerView(
    'form-builder-tab-on-submit',
    null,
    {
      tab: 'debug'
    }
  );
});
