import React, { useCallback } from 'react';
import { Dropdown } from 'rsuite';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { defaultForm } from '../../../helpers/default-form';

const ClearFormMenuItem = () => {
  const setForm = useFormBuilderStore(state => state.setForm);
  const setEditMode = useFormBuilderStore(state => state.setEditMode);
  const setSidebar = useFormBuilderStore(state => state.setSidebar);
  const setGeneration = useFormBuilderStore(state => state.setGeneration);

  const handleEmptyForm = useCallback(
    () => {
      if (window.confirm('Create an empty form and discard current one?')) {
        const emptyForm = defaultForm();
        setForm(defaultForm(emptyForm));
        //setPersistedForm(emptyForm);
        setEditMode(true);
        setSidebar('form');
        setGeneration(generation => generation + 1);
      }
    },
    [setEditMode, setForm, setGeneration, setSidebar]
  );

  return (
    <Dropdown.Item onSelect={handleEmptyForm}>Remove all fields</Dropdown.Item>
  );
};

export { ClearFormMenuItem };
