import { definePlugin } from '../../code-plug';

import { ImportFormMenuItem } from './views/import-form-button';

definePlugin('builder-import-form', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    ImportFormMenuItem,
    {
      parent: 'Form',
      group: 'import-export',
      key: 'form-builder-toolbar-import-form'
    }
  );
});
