import { gql } from '@apollo/client';

export const GET_COMPONENTS = gql`
query($ids: [GraphQLStringOrFloat]) {
  projects(
    sort: "name",
    filter: {
      id: {
        _in: $ids
      }
    }
  ) {
    id,
    name,
    description
  }
forms(filter: {
    projectId: {
      id: {
        _in: $ids
      }
    }
  }) {
    id,
    name,
    versions(sort: "-id", limit: 1) {
      id,
      date_created,
      date_updated
    },
    projectId {
      id
    }
  }
}`;