import { reduceFields, mapFields } from '../../../components/lets-form';

const makeFieldNameUnique = fieldName => {
  const match = fieldName.match(/_([0-9]*)$/);

  if (match) {
    const idx = parseInt(match[1], 10);
    if (!isNaN(idx)) {
      return fieldName.replace(/_([0-9]*)$/, '') + '_' + String(idx + 1);
    }
    return fieldName + '_1';
  }

  return fieldName + '_1';
};

/**
 * makeFormFieldsUnique
 * Check if the new form that needs to be merged with the actual form has already
 * some existing field name, in that case rename incrementally
 * @param {*} newForm
 * @param {*} actualForm
 * @returns
 */
const makeFormFieldsUnique = (newForm, actualForm) => {

  const actualFields = reduceFields(
    actualForm.fields,
    (field, accumulator) => [...accumulator, field.name],
    []
  );

  const newFields = mapFields(
    newForm.fields,
    field => {
      // if field name already exists
      if (actualFields.includes(field.name)) {
        // iterate until the new field name doesn't clash anymore
        let newFieldName = field.name;
        while (actualFields.includes(newFieldName)) {
          newFieldName = makeFieldNameUnique(newFieldName);
        }
        // store new field name
        actualFields.push(newFieldName);
        // return new field
        return { ...field, name: newFieldName };
      }
      return field;
    }
  );

  // return, keep referential integrity
  if (newFields !== newForm.fields) {
    return {
      ...newForm,
      fields: newFields
    };
  }
  return newForm;
};

export { makeFormFieldsUnique };
