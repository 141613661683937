import _ from 'lodash';

import { FRAMEWORKS } from '../manifest';

const SPACER = '  ';
// for these field prefer the string forma {`...`} since it will wrap
const EXTENDED_ATTRIBUTES = ['validate', 'script'];

const objToAtteibutes = (obj, tab = '', framework) => {

  // flatten object for those properties which are scoped under a particular framework, remove others
  const flattenedObj = Object.keys(obj)
    .filter(key => key !== 'validation')
    .reduce(
      (acc, key) => {
        if (key === framework) {
          return {
            ...acc,
            ...obj[framework]
          };
        }
        return acc;
      },
      { ...(_.omit(obj, FRAMEWORKS, 'validation')) }
    );

    if (obj.validation) {
      Object.keys(obj.validation)
        .forEach(key => {
          if (key === 'message') {
            flattenedObj.errorMessage = obj.validation.message;
          } else if (key === 'validate') {
            flattenedObj.validate = obj.validation.validate;
          } else {
            flattenedObj['validation' + _.capitalize(key)] = obj.validation[key];
          }
        });
    }

  const attributes = Object.keys(flattenedObj)
    // remove null or undefined values, also empty arrays
    .filter(key => flattenedObj[key] !== null &&
      flattenedObj[key] !== undefined &&
      (!_.isArray(flattenedObj[key]) || !_.isEmpty(flattenedObj[key]))
    )
    .reduce(
      (acc, key) => {
        let value;
        if (_.isString(flattenedObj[key]) && EXTENDED_ATTRIBUTES.includes(key)) {
          value = '{`' + flattenedObj[key] + '`}';
        } else if (_.isString(flattenedObj[key])) {
          value = JSON.stringify(flattenedObj[key]);
        } else if (_.isFunction(flattenedObj[key])) {
          value = '{' + flattenedObj[key].toString() + '}';
        } else if (_.isObject(flattenedObj[key]) || _.isArray(flattenedObj[key])) {
          const formattedJson =  JSON.stringify(flattenedObj[key], null, 2)
            .split('\n')
            .map((s, idx) => idx !== 0 ? tab + SPACER + s: s)
            .join('\n');
          value = '{' + formattedJson + '}';
        } else {
          value = '{' + JSON.stringify(flattenedObj[key]) + '}';
        }
        return [...acc, `${tab}${SPACER}${key}=` + value];
      },
      []
    );

  return attributes.join('\n');
}

const fieldsToMarkup = (fields, tab, framework) => {
  return (fields ?? [])
    .map(field => fieldToMarkup(field, tab, framework))
    .join('\n');
};

const fieldToMarkup = (field, tab = '', framework) => {
  if (field.component === 'steps') {
    return `${tab}<LfSteps
${objToAtteibutes(_.omit(field, 'component', 'label', 'steps', 'fields'), tab)}
${tab}>
${field.steps.map(step => (
`${tab}${SPACER}<LfStep
${objToAtteibutes(_.omit(step), tab + SPACER)}
${tab}${SPACER}>
${fieldsToMarkup(field.fields?.[step.value], tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfStep>`)).join('\n')}${tab}</LfSteps>`;
} else if (field.component === 'tabs') {
    return `${tab}<LfTabs
${objToAtteibutes(_.omit(field, 'component', 'label', 'tabs', 'fields'), tab)}
${tab}>
${field.tabs.map(currentTab => (
`${tab}${SPACER}<LfTab
${objToAtteibutes(_.omit(currentTab), tab + SPACER)}
${tab}${SPACER}>
${fieldsToMarkup(field.fields?.[currentTab.value], tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfTab>`)).join('\n')}${tab}</LfTabs>`;
} else if (field.component === 'columns') {
    return `${tab}<LfColumns
${objToAtteibutes(_.omit(field, 'component', 'label', 'columns', 'fields'), tab)}
${tab}>
${field.columns.map(column => (
`${tab}${SPACER}<LfColumn
${objToAtteibutes(_.omit(column), tab + SPACER)}
${tab}${SPACER}>
${fieldsToMarkup(field.fields?.[column.name], tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfColumn>`)).join('\n')}
${tab}</LfColumns>`;
} else if (field.component === 'two-columns') {
    return `${tab}<LfColumns
${objToAtteibutes(_.omit(field, 'component', 'leftFields', 'rightFields'), tab)}
${tab}>
${tab}${SPACER}<LfColumn>
${fieldsToMarkup(field.leftFields, tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfColumn>
${tab}${SPACER}<LfColumn>
${fieldsToMarkup(field.rightFields, tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfColumn>
${tab}</LfColumns>`;
  } else if (field.component === 'three-columns') {
    return `${tab}<LfColumns
${objToAtteibutes(_.omit(field, 'component', 'leftFields', 'rightFields', 'centerFields'), tab, framework)}
${tab}>
${tab}${SPACER}<LfColumn>
${fieldsToMarkup(field.leftFields, tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfColumn>
${tab}${SPACER}<LfColumn>
${fieldsToMarkup(field.centerFields, tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfColumn>
${tab}${SPACER}<LfColumn>
${fieldsToMarkup(field.rightFields, tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfColumn>
${tab}</LfColumns>`;
  } else if (field.component === 'array') {
    return `${tab}<LfArray
${objToAtteibutes(_.omit(field, 'component', 'fields'), tab, framework)}
${tab}>
${fieldsToMarkup(field.fields, tab + SPACER, framework)}
${tab}</LfArray>`;
  } else if (field.component === 'steps') {
    return 'steps';
  } else if (field.component === 'group') {
    return `${tab}<LfGroup
${objToAtteibutes(_.omit(field, 'component', 'fields'), tab, framework)}
${tab}>
${fieldsToMarkup(field.fields, tab + SPACER, framework)}
${tab}</LfGroup>`;
  } else if (field.component === 'tabs') {

    const innerTabs = field.tabs.map(lfTab => (
`${tab}${SPACER}<LfTab
${objToAtteibutes(lfTab, tab + SPACER, framework)}
${tab}${SPACER}>
${fieldsToMarkup(field.fields?.[lfTab.value], tab + SPACER + SPACER, framework)}
${tab}${SPACER}</LfTab>`
    ));

    return `${tab}<LfTabs
${objToAtteibutes(_.omit(field, 'component', 'tabs', 'fields'), tab, framework)}
${tab}>
${innerTabs.join('\n')}
${tab}</LfTabs>`;
  } else {
    return `${tab}<LfField
${tab}${SPACER}component="${field.component}"
${objToAtteibutes(_.omit(field, 'component'), tab, framework)}
${tab}/>`;
  }
};


/**
 * jsonToReact
 * Transform a json form schema is the same representation in react tags
 * @param {JSON} json The JSON form schema to converto to React markup
 * @param {String} framework The framework to export to, if there are some framework specific params, then merge those into markup
 */
const jsonToReact = (
  json,
  framework,
  { tab = '', props = {} } = {}
) => {
  const children = json.fields.map(field => fieldToMarkup(field, tab + SPACER, framework));
  // collect props of <LetsForm> component, both from json and explicit
  const formProps = {
    ..._.omit(json, 'version', 'connectors', 'fields'),
    ...props
  };

  const markup = (`${tab}<LetsForm
${objToAtteibutes(formProps, tab, framework)}
${tab}>
${children.join('\n')}
${tab}</LetsForm>`
  );

  return markup;
};

export { jsonToReact };
