import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


const httpLink = createHttpLink({
  uri: 'https://dashboard.letsform.dev/graphql',
});

const authLink = setContext((_, context) => {
  const { headers } = context;
  const token = localStorage.getItem('access_token');

  // if skip request or token is empty
  if (context.noAuth === true || !token) {
    return context;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  };
});

// DOC: https://www.apollographql.com/docs/react/api/core/ApolloClient
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

export const AppolloDataLayer = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
