import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, IconButton, Whisper, Tooltip } from 'rsuite';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import classNames from 'classnames';
import PeoplesIcon from '@rsuite/icons/Peoples';


import { MarkdownText } from '../../../components';

import './project-card.scss';

const PROJECT_VISIBILITY_PUBLIC = '2';

const renderIconButton = (props, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      icon={<ArrowDownLineIcon />}
      color="blue"
      appearance="ghost"
      size="sm"
    />
  );
};


const ProjectCard = ({
  project,
  disabled,
  onSelect = () => {},
  canUse = true
}) => {

  const handleSelect = useCallback(
    key => {
      onSelect(key, project);
    },
    [project, onSelect]
  );

  const inner = (
    <div className={classNames('lf-project-card', { disabled, 'cannot-use': !canUse })}>
      {canUse && (
        <Dropdown
          renderToggle={renderIconButton}
          disabled={disabled}
          onSelect={handleSelect}
          noCaret
          placement="bottomEnd"
          className="form-menu" title="&nbsp;" appearance="primary" size="xs" >
          <Dropdown.Item eventKey="cmd:editProject">Edit project</Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey="cmd:deleteProject">Delete</Dropdown.Item>
        </Dropdown>
      )}
      {canUse && (
        <Link
          className="name"
          to={`/projects/${project.id}`}
        >{project.name}</Link>
      )}
      {!canUse && (
        <span className="name">{project.name}</span>
      )}
      <MarkdownText className="description">
        {project.description}
      </MarkdownText>
      <div className="footer">
        {project.visibility === PROJECT_VISIBILITY_PUBLIC && (
          <div className="visibility">
            <Whisper
              placement="bottomStart"
              trigger="hover"
              speaker={<Tooltip>Project is public, forms in this project are accesible without permissions</Tooltip>}
            >
              <PeoplesIcon />
            </Whisper>
          </div>
        )}
        {project.formsCount ?
          <div className="count"><b>{project.formsCount}</b> forms</div>
          : <>&nbsp;</>
        }
      </div>
    </div>
  );

  if (canUse) {
    return inner;
  } else {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        speaker={
          <Tooltip>
            This form is not longer accessible due to the current plan limitation,
            please upgrade use it again
          </Tooltip>
        }
      >
        {inner}
      </Whisper>
    );
  }
};

export { ProjectCard };
